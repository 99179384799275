import { useCurrentOrganization } from "~/business-areas/organization/composables/current-organization.hook";
import { useCurrentOrganizationMember } from "~/business-areas/organization-member/composables/current-organization-member.hook";

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name === "auth-callback") return;

  const organization = useCurrentOrganization();
  const member = useCurrentOrganizationMember();
  const user = useSupabaseUser();
  const { $analytics } = useNuxtApp();

  if (user.value) {
    await Promise.all([organization.ensureIsLoaded(), member.ensureIsLoaded()]);
  } else {
    organization.reset();
  }

  if (user.value && to.name === "auth-login") {
    return await navigateTo((to.query.redirect as string) ?? "/");
  }

  if (user.value && useState("identify_id").value !== user.value.id) {
    $analytics.identify(user.value.id, {
      email: user.value.email,
      first_name: member.member.value?.user.first_name,
      last_name: member.member.value?.user.last_name,
      organization: organization.organization.value?.slug,
    });

    useState("identify_id").value = user.value.id;
  }

  if (to.meta.requiresAuthentication && !user.value) {
    return await navigateTo({
      name: "auth-login",
      query: {
        redirect: to.path,
      },
    });
  }
});
