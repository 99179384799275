<script lang="ts">
import { dialogEventHook } from "~/shared/dialog/composables/dialog.hook";
import { type DialogId, dialogRegistry } from "~/shared/dialog/dialog.registry";

// eslint-disable-next-line import/no-default-export
export default defineComponent({
  components: dialogRegistry,

  setup() {
    const isOpen = ref(false);
    const activeDialogId = ref<DialogId | undefined>();
    const activeDialogBinding = ref<Record<string, unknown> | undefined>();
    const isSwitchingDialog = ref(false);

    dialogEventHook.open.on(async (event) => {
      if (isOpen.value) {
        isSwitchingDialog.value = true;
        activeDialogId.value = undefined;
        isOpen.value = false;
        activeDialogBinding.value = undefined;

        await nextTick();
      }

      activeDialogId.value = event.id;
      activeDialogBinding.value = event.binding;
      isOpen.value = true;
      isSwitchingDialog.value = false;
    });

    dialogEventHook.close.on(() => {
      if (isSwitchingDialog.value) return;

      activeDialogId.value = undefined;
      isOpen.value = false;
      activeDialogBinding.value = undefined;
    });

    watch(isOpen, (value) => {
      if (!value) {
        activeDialogId.value = undefined;
        activeDialogBinding.value = undefined;
      }
    });

    return () =>
      isOpen.value
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          h(dialogRegistry[activeDialogId.value], {
            ...activeDialogBinding.value,
            modelValue: isOpen.value,
            "onUpdate:modelValue": (value: boolean) => (isOpen.value = value),
          })
        : undefined;
  },
});
</script>
